<template>
  <div id="login">
    <div class="top">
      <img src="../assets/images/login/logo.png" alt />
      <p>欢迎登录</p>
    </div>
    <div class="login">
      <!-- 用户名输入框 -->
      <div>
        <p :class="['item', { 'login-focus': users }]">请输入用户名</p>
        <input
          v-model.trim="userName"
          maxlength="30"
          type="text"
          @focus="userFocus"
          @blur="userBlur"
        />
        <img
          :class="[{'login-focus-img': users}]"
          src="../assets/images/login/user.png"
          alt
        />
      </div>

      <!-- 密码输入框 -->
      <div>
        <p :class="['item', { 'login-focus': words }]">请输入登录密码</p>
        <input
          v-model.trim="passWord"
          maxlength="15"
          :type="passwordType"
          @focus="wordFocus"
          @blur="wordBlur"
        />
        <img
          :class="[{'login-focus-img': words}]"
          src="../assets/images/login/password.png"
          alt
        />
      </div>

      <!-- 验证码输入框 -->
      <div>
        <p :class="['item', { 'login-focus': codeFocus }]">请输入验证码</p>
        <input
          v-model.trim="verifyCode"
          maxlength="6"
          type="text"
          @focus="codeFocus = true"
          @blur="codeFocus = verifyCode !== ''"
        />
        <img
          :src="captchaUrl"
          alt="验证码"
          @click="refreshCaptcha"
          style="position: absolute; right: 0.27rem; top: 0.4rem; width: 1rem; height: 0.4rem; cursor: pointer;"
        />
      </div>

      <!-- 登录按钮 -->
      <div
        class="comfirmBtn"
        :class="{ blue: userName && passWord && verifyCode && unbind }"
        @click="login()"
      >
        登录
      </div>
    </div>

    <!-- 冻结弹窗 -->
    <Modal :value="modalShow" @on-cancel="affirmClose" />

    <!-- 选择登录角色 -->
    <BtmModal
      v-if="flag"
      :text="title_text"
      :value="roleModalShow"
      :data="list"
      @on-click="choiceRole"
      @on-cancel="choiceCancel"
    />
  </div>
</template>

<script>
import port from '@/api/port';
import cookies from '@/utils/cookie.js';

export default {
  name: 'Login',
  data() {
    return {
      userName: '', // 用户名
      passWord: '', // 密码
      verifyCode: '', // 验证码
      passwordType: 'password', // 密码类型
      users: false, // 用户名输入框焦点状态
      words: false, // 密码输入框焦点状态
      codeFocus: false, // 验证码输入框焦点状态
      unbind: true, // 是否允许登录
      modalShow: false, // 冻结提示窗
      title_text: '请选择登录角色', // 弹窗标题
      roleModalShow: false, // 选择角色弹窗
      list: [], // 角色列表
      flag: false, // 是否显示角色选择弹窗
      captchaUrl: '', // 验证码 URL
    };
  },
  methods: {
    // 页面滚动调整
    kickBack() {
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollTop + 1);
        document.body.scrollTop >= 1 &&
          window.scrollTo(0, document.body.scrollTop - 1);
      }, 10);
    },

    // 显示错误信息
    showError(message) {
      this.$toast({ message });
    },

    // 登录逻辑
    login() {
      if (!this.userName || !this.passWord || !this.verifyCode || !this.unbind) {
        this.showError('请填写完整信息后登录！');
        return;
      }

      this.kickBack();
      const data = {
        method: '100000',
        username: this.userName,
        password: this.passWord,
        verifyCode: this.verifyCode,
      };

      port.portAddress(data).then((res) => {
        if (res.status == 200) {
          cookies.set('token', res.data.token);
          this.list = res.data.store;
          this.flag = true;
          this.roleModalShow = true;
        } else {
          this.showError(res.msg);
          if (res.msg === '验证码错误') {
            this.refreshCaptcha(); // 如果验证码错误，刷新验证码
          }
        }
      });
    },

    // 选择角色
    choiceRole(val) {
      this.roleModalShow = false;

      const data = {
        method: '100001',
        store_id: val.id,
        token: cookies.get('token'),
      };

      port.portAddress(data).then((res) => {
        if (res.status == 200) {
          this.$router.replace({
            path: '/home',
          });
        } else {
          this.showError(res.msg);
        }
      });
    },

    // 取消选择角色
    choiceCancel() {
      this.roleModalShow = false;
      this.selectedRole = null; // 清空已选角色
    },

    // 冻结提示关闭
    affirmClose() {
      this.modalShow = false;
    },

    // 用户名输入框聚焦
    userFocus() {
      this.users = true;
    },

    // 用户名输入框失去焦点
    userBlur() {
      this.users = this.userName !== '';
    },

    // 密码输入框聚焦
    wordFocus() {
      this.words = true;
    },

    // 密码输入框失去焦点
    wordBlur() {
      this.words = this.passWord !== '';
    },

    // 切换密码可见性
    togglePasswordVisibility() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
    },

    // 刷新验证码
    async refreshCaptcha() {
      try {
        const response = await port.getCaptcha(); // 调用修改后的 getCaptcha 方法
        if (response && response.url) {
          const baseUrl = process.env.VUE_APP_BASE_URL;
          this.captchaUrl = baseUrl + `${response.url}?_t=${new Date().getTime()}`; // 更新验证码 URL
        } else {
          console.error('验证码加载失败:', response);
          this.showError('验证码加载失败，请重试！');
        }
      } catch (error) {
        console.error('刷新验证码失败:', error);
        this.showError('验证码加载失败，请检查网络或稍后再试！');
      }
    }
  },
  created() {
    // 页面加载时初始化验证码
    this.refreshCaptcha();
  },
};
</script>

<style lang="less" scoped>
#login {
  background: #fff url("../assets/images/background.png") no-repeat center center;
  background-size: cover;
  height: 100vh;
  position: relative;

  .top {
    padding-top: 2rem;
    text-align: center;
    margin-bottom: 1.4rem;

    img {
      width: 0.96rem;
      margin-bottom: 0.14rem;
    }

    p {
      font-size: 0.41rem;
      color: #004a8f;
    }
  }

  & > .login {
    margin: 0 0.27rem;

    & > div {
      height: 1.14rem;
      padding: 0 0.27rem;
      position: relative;

      img {
        position: absolute;
        width: 0.27rem;
        height: 0.27rem;
        top: 0.46rem;
        transition: all 0.3s ease-in-out;
      }

      input {
        display: block;
        width: 5.34rem;
        padding-bottom: 0.2rem;
        background: transparent;
        outline: none;
        border: none;
        border-bottom: 0.01rem solid #cee3fb;
        font-size: 0.24rem;
        color: #333;
        text-indent: 0.35rem;
        position: absolute;
        top: 0.6rem;
        border-radius: 0;
      }

      p.item {
        position: absolute;
        font-size: 0.24rem;
        color: #999;
        padding: 0 0.47rem;
        left: 0.09rem;
        top: 0.44rem;
        transition: all 0.3s ease-in-out;
        z-index: 0;
      }

      .login-focus {
        top: 0.14rem !important;
        font-size: 0.2rem !important;
      }

      .login-focus-img {
        top: 0.63rem !important;
      }
    }

    .comfirmBtn {
      width: 5.38rem;
      height: 0.68rem;
      line-height: 0.68rem;
      background: #d5e8fc;
      border-radius: 0.1rem;
      text-align: center;
      font-size: 0.27rem;
      color: #fff;
      margin: 1.28rem auto 0;
      box-sizing: border-box;
      transition: background 0.3s ease-in-out;

      &:hover {
        background: #b9daff;
      }

      &.blue {
        background: #2f8af1;

        &:hover {
          background: #1e73be;
        }
      }
    }
  }
}
</style>